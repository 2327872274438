import React, { Component } from "react"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import { Link } from "gatsby"

import "../../../css/MediaPage/media.css"
export default class media extends Component {
  render() {
    const options = [
      {
        name: "Press Release",
        link: "/resources/media/press-releases",
      },
      {
        name: "In the News",
        link: "/resources/media/inthenews",
      },
      {
        name: "Contact -  SurajR@tataclassedge.com",
        link: "#",
      },
    ]

    return (
      <Layout>
        <SEO
          title="Media | Tata Studi"
          lang="en"
          description="Find resources to help your child in developing a good study habit and become an independent and confident learner | Tata Studi"
        />
        <div className="pageBanner blogsPage" style={{ position: "initial" }} />
        <div className="blogs_banner ">
          <span>Media</span>
        </div>

        <div className="container-fluid mx-auto d-flex justify-content-center font-samsung">
          <div className="media-menu">
            {options.map((item, key) => {
              return (
                <Link
                  to={item.link}
                  className="card text-left border-0 m-title my-1 py-3 px-2 media-menu-item"
                  key={key}
                >
                  <div>{item.name}</div>
                </Link>
              )
            })}
          </div>
        </div>
      </Layout>
    )
  }
}
